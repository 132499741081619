<template>
  <div>
    <h4>CATEGORIAS</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.dFormCr.show();
        $refs.formCr.reset();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>NOMBRE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td>{{ l.id }}</td>
            <td>{{ l.name }}</td>
            <td>
              <button
                class="btn btn-light btn-sm"
                @click="
                  $refs.dPreDel.show();
                  $refs.dPreDel.val = l;
                "
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dFormCr">
      <Form
        ref="formCr"
        @submitted="
          $refs.tr.gl();
          $refs.dFormCr.hide();
        "
      ></Form>
    </app-modal-basic>

    <app-modal-yn
      ref="dPreDel"
      @yes="delItem($refs.dPreDel.val.id)"
      @no="$refs.dPreDel.hide()"
    >
      <p>
        Eliminar esta categoria
        <span v-if="$refs.dPreDel && $refs.dPreDel.val">
          <b>{{ $refs.dPreDel.val.name }}</b> </span
        >?
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import Form from "./Form";
import { GeneralService } from "../GeneralService";

export default {
  components: {
    Form
  },
  data: () => ({
    list: {}
  }),
  methods: {
    delItem(id) {
      GeneralService.deleteCategorie(id).then(() => {
        this.$refs.dPreDel.hide();
        this.$refs.tr.gl();
      });
    },
    getList(params) {
      return new Promise(rsv => {
        GeneralService.getCategories(params).then(res => {
          this.list = res.data;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
